import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import { Interval } from '../components/result/age-antispoofing/age-displayer/range-age-displayer/RangeAgeDisplayer';
import {
    AgeDisplayMode,
    Endpoint,
    LevelOfAssuranceField,
    MetadataField,
    OperatorField,
    SecureMode,
} from '../models/prediction';
import { getArrayFromString } from '../utils/types';

export default {
    // In development environment use the REACT_BACKEND_URL env variable. In
    // production, use the ${BACKEND_URL} placeholder. It will be substituted
    // before running the application (See 'docker/start.sh' script).
    // Remember that env variables must have REACT_APP prefix.
    apiUrl: process.env.REACT_APP_BACKEND || 'https://${BACKEND_URL}/api',
    version: process.env.REACT_APP_VERSION || '',
    env: process.env.NODE_ENV,
    stdDevThreshold:
        parseFloat(
            process.env.REACT_APP_STD_DEV_THRESHOLD
                ? process.env.REACT_APP_STD_DEV_THRESHOLD
                : '${STD_DEV_THRESHOLD}',
        ) || 6.0, // Default stdDevThreshold provided by R&D team to determine when a predicted age is accurate.
    showFaceMatching:
        (process.env.REACT_APP_SHOW_FACE_MATCHING
            ? process.env.REACT_APP_SHOW_FACE_MATCHING
            : '${SHOW_FACE_MATCHING}') === 'true',
    // TODO: This is temporary. When the user role system will be done, we will delete this option.
    showStoreImagesOption:
        (process.env.REACT_APP_SHOW_STORE_IMAGES_OPTION
            ? process.env.REACT_APP_SHOW_STORE_IMAGES_OPTION
            : '${SHOW_STORE_IMAGES_OPTION}') === 'true',
    levelAssuranceValues: getArrayFromString(
        process.env.REACT_APP_LEVEL_ASSURANCE_VALUES
            ? process.env.REACT_APP_LEVEL_ASSURANCE_VALUES
            : '${LEVEL_ASSURANCE_VALUES}',
    ) as LevelOfAssuranceField[],
    showConsent:
        (process.env.REACT_APP_SHOW_CONSENT
            ? process.env.REACT_APP_SHOW_CONSENT
            : '${SHOW_CONSENT}') === 'true',
    allowedRolesForEndpointsConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_ENDPOINTS_CONFIG ||
            '${ALLOWED_ROLES_FOR_ENDPOINTS_CONFIG}',
    ),
    allowedRolesForMetadataConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_METADATA_CONFIG ||
            '${ALLOWED_ROLES_FOR_METADATA_CONFIG}',
    ),
    allowedRolesForLevelOfAssuranceConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_LEVEL_OF_ASSURANCE_CONFIG ||
            '${ALLOWED_ROLES_FOR_LEVEL_OF_ASSURANCE_CONFIG}',
    ),
    allowedRolesForOperatorConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_OPERATOR_CONFIG ||
            '${ALLOWED_ROLES_FOR_OPERATOR_CONFIG}',
    ),
    allowedRolesForThresholdConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_THRESHOLD_CONFIG ||
            '${ALLOWED_ROLES_FOR_THRESHOLD_CONFIG}',
    ),
    allowedRolesForSecureModeConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_SECURE_MODE_CONFIG ||
            '${ALLOWED_ROLES_FOR_SECURE_MODE_CONFIG}',
    ),
    allowedRolesForConfigWrapperConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_CONFIG_WRAPPER_CONFIG ||
            '${ALLOWED_ROLES_FOR_CONFIG_WRAPPER_CONFIG}',
    ),
    allowedRolesForAgeDisplayModeConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_AGE_DISPLAY_MODE_CONFIG ||
            '${ALLOWED_ROLES_FOR_AGE_DISPLAY_MODE_CONFIG}',
    ),
    allowedRolesForLanguageConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_LANGUAGE_CONFIG ||
            '${ALLOWED_ROLES_FOR_LANGUAGE_CONFIG}',
    ),
    defaultConfig: {
        endpoint: (process.env.REACT_APP_DEFAULT_ENDPOINTS_CONFIG ||
            '${DEFAULT_ENDPOINTS_CONFIG}') as Endpoint,
        levelOfAssurance: (process.env.REACT_APP_DEFAULT_LEVEL_OF_ASSURANCE_CONFIG ||
            '${DEFAULT_LEVEL_OF_ASSURANCE_CONFIG}') as LevelOfAssuranceField,
        metadata: (process.env.REACT_APP_DEFAULT_METADATA_CONFIG ||
            '${DEFAULT_METADATA_CONFIG}') as MetadataField,
        operator: (process.env.REACT_APP_DEFAULT_OPERATOR_CONFIG ||
            '${DEFAULT_OPERATOR_CONFIG}') as OperatorField,
        threshold: parseInt(
            process.env.REACT_APP_DEFAULT_THRESHOLD_CONFIG || '${DEFAULT_THRESHOLD_CONFIG}',
            10,
        ) as number,
        mode: (process.env.REACT_APP_DEFAULT_MODE_CONFIG || '${DEFAULT_MODE_CONFIG}') as SecureMode,
        ageDisplayMode: (process.env.REACT_APP_DEFAULT_AGE_DISPLAY_MODE_CONFIG ||
            '${DEFAULT_AGE_DISPLAY_MODE_CONFIG}') as AgeDisplayMode,
        ageDisplayInterval: Number(
            process.env.REACT_APP_AGE_DISPLAY_INTERVAL_CONFIG || '${AGE_DISPLAY_INTERVAL_CONFIG}',
        ) as Interval,
        language: (process.env.REACT_APP_DEFAULT_LANGUAGE_CONFIG ||
            '${DEFAULT_LANGUAGE_CONFIG}') as LANGUAGE_CODE,
    },
};
