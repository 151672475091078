import { ALERT_TYPES } from '@yoti/react-components/alert';
import React, { useCallback, useEffect } from 'react';
import { useGlobalContext } from '../../globalContext';
import { useNotify, usePredict } from '../../hooks';
import { FCMPayload } from '../../models/faceCaptureModule';
import { Endpoint, RequestBody, getMetadataStruct } from '../../models/prediction';
import { SESSION_KEY } from '../../utils/constants/storage';
import { ConfigProps } from '../config/ConfigPanel';
import ResultAgeAntispoofing from '../result/age-antispoofing';
import Scan from '../scan';

interface ScanPanelState {
    fcmResult: FCMPayload;
    base64ImagePreview?: string;
}

const ScanPanel: React.FC = () => {
    const [state, setState] = React.useState<ScanPanelState>({ fcmResult: { img: '' } });
    const {
        scanConfiguration: configuration,
        setScanConfiguration: setConfiguration,
    } = useGlobalContext();
    const { predict, prediction, error, abort, request } = usePredict(
        configuration.selectedEndpoint,
        configuration.selectedSecureMode,
    );

    const { fcmResult, base64ImagePreview } = state;

    const notify = useNotify();

    const notifyError = useCallback(
        (error: Error) => {
            if (localStorage.getItem(SESSION_KEY)) {
                notify({
                    message: error.message,
                    type: ALERT_TYPES.DANGER,
                    icon: true,
                });
            }
        },
        [notify],
    );

    // Methods to filter the fields that the application will send to the AI
    // service.
    const allowMetadata = () =>
        configuration.selectedEndpoint !== Endpoint.SelfCheckoutAntiSpoofing;
    const allowLevelOfAssurance = () =>
        configuration.selectedEndpoint !== Endpoint.Age &&
        configuration.selectedEndpoint !== Endpoint.AgeVerify &&
        configuration.selectedEndpoint !== Endpoint.SelfCheckoutAntiSpoofing;
    const isVerifyEndpoint = () =>
        configuration.selectedEndpoint === Endpoint.AgeVerify ||
        configuration.selectedEndpoint === Endpoint.AgeAntispoofingVerify;

    const onSuccess = (res: FCMPayload, base64ImagePreview?: string) => {
        setState((previous) => ({ ...previous, fcmResult: res, base64ImagePreview }));
        const data: RequestBody = {
            ...res,
            metadata: allowMetadata()
                ? getMetadataStruct(configuration.selectedMetadataField)
                : undefined,
            level_of_assurance: allowLevelOfAssurance()
                ? configuration.selectedLevelOfAssuranceField
                : undefined,
            operator: isVerifyEndpoint() ? configuration.selectedOperatorField : undefined,
            threshold: isVerifyEndpoint() ? configuration.selectedThresholdField : undefined,
        };
        predict(data);
    };
    const onError = useCallback((error: Error) => notifyError(error), [notifyError]);
    const backCallback = useCallback(() => {
        setState((previous) => ({
            ...previous,
            fcmResult: { img: '' },
            base64ImagePreview: '',
            result: undefined,
        }));
    }, []);

    const onCfgChange = useCallback(
        (cfg: ConfigProps) => {
            setConfiguration(cfg);
            backCallback(); // Reset prediction as the configuration changed.
        },
        [setConfiguration, backCallback],
    );

    // Abort the prediction request if the user navigates to another page.
    useEffect(() => {
        return abort;
    }, [abort]);

    // Reset the image if the prediction request fails.
    useEffect(() => {
        if (error && fcmResult.img) {
            setState({ fcmResult: { img: '' } });
            onError(error);
        }
    }, [error, onError, fcmResult.img]);

    return base64ImagePreview ? (
        <ResultAgeAntispoofing
            image={base64ImagePreview}
            result={prediction}
            request={request}
            onBackButtonClick={backCallback}
            configuration={configuration}
        />
    ) : (
        <Scan
            onSuccess={onSuccess}
            onError={onError}
            configuration={configuration}
            setConfiguration={onCfgChange}
        />
    );
};

export default ScanPanel;
