import { Endpoint } from '../../models/prediction';
import { ConfigProps } from '../config/ConfigPanel';
import config from '../../utils/config';

export const DEFAULT_CONFIG: ConfigProps = {
    selectedEndpoint: config.defaultConfig.endpoint,
    selectedSecureMode: config.defaultConfig.mode,
    endpoints: [
        { key: Endpoint.Antispoofing },
        { key: Endpoint.Age },
        { key: Endpoint.AgeAntispoofing },
        { key: Endpoint.AgeAntispoofingVerify },
        { key: Endpoint.AgeVerify },
        { key: Endpoint.SelfCheckoutAntiSpoofing },
    ],
    selectedAgeDisplayMode: config.defaultConfig.ageDisplayMode,
    selectedLanguage: config.defaultConfig.language,
    consent: false,
};
